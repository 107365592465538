import { Box, Breadcrumbs, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormObserver,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import { LoaderComponentProps } from "../../../../AppComponents/Loader";
import { AppConfig } from "../../../../Config/app-config";
import { HocBackdropLoader } from "../../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import BroadcastPreview from "../../BroadcastPreview";
import { SMS_PRIMARY_COLOR } from "../../BroadcastPreview/Constants";
import { convertDateTimeToUTC } from "../../WhatsApp/Utils/utils";
import { StyledContentBox } from "../BroadCastTemplate/Styles";
import { BreadcrumbsItem, SMSBoxStepperbar, SMSBoxTopbar, StyledCreateBroadcastWrap } from "./Styles";

import { SMSActions } from "../../../../Store/Slices/SMS/sms.actions";
import { SMSDataStore } from "../../../../Store/Slices/SMS/sms.selectors";
import { resetTemplateData, testSMSData } from "../../../../Store/Slices/SMS/sms.slice";
import { SMSNetworkService } from "../../../../Store/Slices/SMS/smsNetworkService";
import {
  BROADCAST_SMS_FORM_DATA,
  SMSBroadcastScheduleStep1Schema,
  SMSBroadcastScheduleStep2Schema,
  SMSBroadcastScheduleStep3Schema,
  SMSBroadcastScheduleStep4Schema,
} from "./Utils/Constants";
import { convertDataToSMSBroadcastForm } from "./Utils/utils";
import RenderFormStep1 from "./component/RenderFormStep1";
import RenderFormStep2 from "./component/RenderFormStep2";
import RenderFormStep3 from "./component/RenderFormStep3";
import RenderFormStep4 from "./component/RenderFormStep4";

import { marketingListActions } from "../../../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../../../Store/Slices/MarketingList/marketingList.selectors";
import { removeMarketingListData } from "../../../../Store/Slices/MarketingList/marketingList.slice";
import BroadcastNoData from "../../Utils/BroadcastNoData";

import { ReactComponent as Confirm } from "../../../../assets/images/confirm.svg";
import { ReactComponent as Recipients } from "../../../../assets/images/recipients.svg";
import { ReactComponent as Schedule } from "../../../../assets/images/schedule.svg";
import { ReactComponent as Setup } from "../../../../assets/images/setup.svg";
import { ReactComponent as Template } from "../../../../assets/images/template.svg";
import { ReactComponent as ResolveVariable } from '../../../../assets/images/ResolveVariable.svg';
import { creditDetailsStore } from "../../../../Store/Slices/CreditDetails/CreditDetails.selectors";
import BroadcastCreditDetails from "../../Utils/BroadcastCreditDetails";
import { StyledCreditBox } from "../../WhatsApp/Templates/Styles";
import { findFormSpecificPermissionKeyUsingLocation } from "../../../../Utils/rolesUtils";
import { ProtectedAppForm } from "../../../../AppComponents/ProtectedComponents";
import { theme } from "../../../../Customization/Theme";
import styled from "styled-components";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";

interface Props extends LoaderComponentProps { }

interface BreadCrumbItem {
  id: number;
  name: string;
  icon: JSX.Element;
}

const BroadcastCreate: React.FC<Props> = ({ setLoading }) => {
  const PREVIEW_AVAILABLE = false;

  const localStorageFormData: any = AppConfig.getUserData(
    BROADCAST_SMS_FORM_DATA
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { templateByIdData, postSMSBroadcastData, smsTemplateListData } =
    useAppSelector(SMSDataStore);
  const { broadcastByIdData }: any = useAppSelector(marketingListStore);
  const { broadcastCreditDetails }: any = useAppSelector(creditDetailsStore);

  const parameters = new URLSearchParams(window.location.search);

  const [broadcastNowPayload, setBroadcastNowPayload] = useState<any>(null);

  //*WILL BE USED TO SET THE FORM ACCESS TYPE 
  const [formAccessType, setFormAccessType] = useState<"create" | "read" | "update" | "delete" | undefined>();
  //*getting the form access type using the location on first render
  useEffect(() => {
    const result = findFormSpecificPermissionKeyUsingLocation();
    if (result === "update" && parameters?.get("view")) {
      setFormAccessType("read");
    }
    else {
      setFormAccessType(result);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [tempalteData, setTemplateData] = useState(
    templateByIdData?.body_value
  );
  const { showAlert } = useNotification();
  let { step_id, broadcast_id } = useParams();
  const [formStep, setFormStep] = useState<number>(
    step_id && broadcast_id
      ? Number(step_id)
      : step_id && templateByIdData
        ? Number(step_id)
        : 1
  );

  const [formikProps, setFormikProps] = useState<any>(null);
  const [isEditingBroadcast, setIsEditingBroadcast] = useState<boolean>(
    step_id && broadcast_id && step_id === String(4) ? true : false
  );
  const [isView, setIsView] = useState<boolean>(
    step_id && broadcast_id && step_id === String(1) ? true : false
  );
  useEffect(() => {
    setTemplateData(templateByIdData?.body_value);
  }, [templateByIdData?.body_value]);
  const { values, errors }: any = formikProps || {};
  const { isShowing, toggle } = useModal();

  const [userInput, setUserInput] = useState<any>(
    localStorageFormData
      ? {
        broadcast_name: localStorageFormData?.broadcast_name,
        broadcast_template_id:
          localStorageFormData?.broadcast_template_id || 0,
        sender_id: localStorageFormData?.sender_id || 0,
        template_type: localStorageFormData?.template_type || 0,
        broadcast_action:
          localStorageFormData?.broadcast_action ||
          "broadcast_marketing_list",
        marketing_list: localStorageFormData?.marketing_list || 0,
        filters: localStorageFormData?.filters || null,
        selected_rows: 0,
        search_term: localStorageFormData?.search_term || "",

        when_to_send: localStorageFormData?.when_to_send || "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),

        resolved_header_variables:
          localStorageFormData?.resolved_header_variables || [],
        resolved_body_variables:
          localStorageFormData?.resolved_body_variables || [],
        resolved_buttons_variables:
          localStorageFormData?.resolved_buttons_variables || [],
        id: localStorageFormData?.id || null,
      }
      : {
        broadcast_name: "",
        broadcast_template_id: 0,
        broadcast_action: "broadcast_marketing_list",
        marketing_list: 0,
        filters: null,
        selected_rows: 0,
        search_term: "",

        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),

        resolved_header_variables: [],
        resolved_body_variables: [],
        resolved_buttons_variables: [],
        id: postSMSBroadcastData?.id,
      }
  );

  //* HANDLE SUBMIT BTN API CALL
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    if (formStep === 1) {
      if (
        localStorageFormData?.id &&
        values?.broadcast_name === localStorageFormData?.broadcast_name &&
        values?.broadcast_template_id ===
        localStorageFormData?.broadcast_template_id &&
        values?.sender_id === localStorageFormData?.sender_id &&
        values?.template_type === localStorageFormData?.template_type
      ) {
        AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, values);
        setFormStep(formStep + 1);
        SubmitProps.setSubmitting(false);
        return;
      } else {
        const payload = payloadObject(values);
        if (localStorageFormData?.id) {
          SMSNetworkService.updateSMSBroadcast(
            payload,
            localStorageFormData?.id
          )
            .then((res: any) => {
              AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, values);
              setFormStep(formStep + 1);
            })
            .catch((err: any) => {
              showAlert("Something went wrong", "error");
            })
            .finally(() => {
              SubmitProps.setSubmitting(false);
            });
        } else {
          dispatch(SMSActions.postSMSBroadcast(payload))
            .then((res: any) => {
              if (!res?.payload?.data?.error && res?.payload?.data) {
                values["id"] = res?.payload?.data?.id;
                AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, values);
                setFormStep(formStep + 1);
              } else {
                showAlert(
                  res?.payload.data?.error || "Something went wrong",
                  "error"
                );
              }
            })
            .catch((err: any) => {
              showAlert("Something went wrong", "error");
            })
            .finally(() => {
              SubmitProps.setSubmitting(false);
            });
        }
        return;
      }
    }

    if (formStep === 2) {
      SubmitProps.setSubmitting(false);
      AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, values);
      setFormStep(formStep + 1);
      return;
    }
    if (formStep === 3) {
      const payload = payloadObject(values);
      SMSNetworkService.updateSMSBroadcast(payload, values?.id)
        .then((res: any) => {
          AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, values);
          setFormStep(formStep + 1);
        })
        .catch((err: any) => {
          showAlert("Something went wrong", "error");
        })
        .finally(() => {
          SubmitProps.setSubmitting(false);
        });
      return;
    }
    if (formStep === 4) {
      const payload = payloadObject(values);
      if (isEditingBroadcast && broadcast_id) {
        setLoading(true);
        payload.status = "scheduled";
        payload.mode = "update";
        if (payload?.scheduled_now) {
          setBroadcastNowPayload({ payload, id: broadcast_id })
          SubmitProps.setSubmitting(false);
          toggle(true);
          return;
        } else {
          SMSNetworkService.updateSMSBroadcast(payload, broadcast_id)
            .then((res: any) => {
              if (res?.data?.error) {
                showAlert(res.data.error, "error");
                return;
              }
              AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
              showAlert("Broadcast created", "success");
              navigate("/broadcast/sms/scheduled");
            })
            .catch((err: any) => {
              showAlert(
                err?.response?.data?.error || "Something went wrong",
                "error"
              );
            })
            .finally(() => {
              SubmitProps.setSubmitting(false);
              setLoading(false);
            });
        }
      } else {
        AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, values);
        SubmitProps.setSubmitting(false);
        if (payload?.scheduled_now) {
          setBroadcastNowPayload({ payload, id: values?.id })
          SubmitProps.setSubmitting(false);
          toggle(true);
          return;
        } else {
          SMSNetworkService.updateSMSBroadcast(payload, values?.id)
            .then((res: any) => {
              AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
              showAlert("Broadcast created", "success");
              navigate("/broadcast/sms/scheduled");
            }).catch((err: any) => {
              showAlert(
                err?.response?.data?.error || "Something went wrong",
                "error"
              );
            }).finally(() => {
              SubmitProps.setSubmitting(false);
              setLoading(false);
            });
        }
      }
    }
  };

  const payloadObject = (values: any) => {
    let payload: any = {
      broadcast_name: values?.broadcast_name.trim() || "",
      broadcast_template_id: values?.broadcast_template_id,
      sender_id: values?.sender_id,
      template_type: values?.template_type,
      broadcast_action: values?.broadcast_action?.value,
      status: "draft",
      components: {},
    };

    if (values?.resolved_body_variables?.length > 0) {
      payload["components"]["body"] = {
        value: values?.resolved_body_variables,
      };
    }

    if (formStep !== 3) {
      delete payload?.["components"];
    }

    if (formStep === 4) {
      payload["broadcast_id"] = values?.id
      payload["scheduled_now"] =
        values?.when_to_send === "broadcast_now" ? true : false;
      payload["scheduled_date"] = null;
      if (values?.when_to_send === "schedule_broadcast") {
        payload["scheduled_date"] = convertDateTimeToUTC(
          values?.date,
          values?.time
        );
      }
    }

    return payload;
  };

  //* only first time render
  useEffect(() => {
    if ((isEditingBroadcast || isView) && broadcast_id) {
      dispatch(
        marketingListActions.getScheduledBroadcastByID(broadcast_id)
      ).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
    return () => {
      dispatch(resetTemplateData({}));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if ((isEditingBroadcast || isView) && broadcastByIdData) {
      setLoading(true);
      let temp = convertDataToSMSBroadcastForm(broadcastByIdData);
      setUserInput(temp);
      setLoading(true);
      dispatch(SMSActions.getTemplateById(temp?.broadcast_template_id)).finally(
        () => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      );
    }
  }, [broadcastByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleFormNavigation(formStep);
  }, [formStep]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
      formikProps?.handleReset();
      setUserInput({
        broadcast_name: "",
        broadcast_template_id: 0,
        broadcast_action: "broadcast_marketing_list",
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",
        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),
        resolved_header_variables: [],
        resolved_body_variables: [],
        resolved_buttons_variables: [],
      });
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormNavigation = (formIndex: number) => {
    if (formIndex === 1) {
      setLoading(true);
      dispatch(SMSActions.getSMSTemplateListData({
        limit: 9999,
        offset: 0,
      })).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      });
    }
    if (!templateByIdData && formStep !== 1) {
      return;
    }
    if (!isView && !isEditingBroadcast) {
      let url = `/broadcast/sms/create-broadcast/${formIndex}`;
      navigate(url);
    }
  };

  const handleBackClick = (isReset?: boolean) => {
    if (isEditingBroadcast || isView) {
      let url = `/broadcast/sms/history`;
      return navigate(url);
    }
    if (formStep === 1) {
      AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
      formikProps?.handleReset();
      dispatch(removeMarketingListData({}))
      setUserInput({
        broadcast_name: "",
        broadcast_template_id: 0,
        broadcast_action: "broadcast_marketing_list",
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",

        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),

        resolved_header_variables: [],
        resolved_body_variables: [],
        resolved_buttons_variables: [],
      });
      if (!isReset || isEditingBroadcast || isView) {
        let url = `/broadcast/sms/history`;
        navigate(url);
      } else {
        setTemplateData("");
      }

      return;
    }

    if (formStep === 2) {
      setFormStep(formStep - 1);
      return;
    }
    if (formStep === 3) {
      setFormStep(formStep - 1);
      return;
    }
    if (formStep === 4) {
      setFormStep(formStep - 1);
      return;
    }
  };

  const [smsTemplateCount, setSmsTemplateCount] = useState<any>([]);

  useEffect(() => {
    setSmsTemplateCount(
      smsTemplateListData?.filter((ele: any) => ele?.status === "APPROVED")
    );
  }, [smsTemplateListData]);

  const handleButtonClick = () => {
    return navigate("/broadcast/sms/template");
  };

  const BreadCrumbsItems: BreadCrumbItem[] = [
    {
      id: 1,
      name: "Template",
      icon: <Template />,
    },
    {
      id: 2,
      name: "Recipients",
      icon: <Recipients />,
    },
    {
      id: 3,
      name: "Review Cost & Confirm",
      icon: <ResolveVariable />,
    },
    {
      id: 4,
      name: "Schedule",
      icon: <Schedule />,
    },
  ];

  return (
    <StyledCreateBroadcastWrap>
      {smsTemplateCount?.length || isEditingBroadcast || isView ? (
        <ProtectedAppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
          }}
          validationSchema={
            (formStep === 1 && SMSBroadcastScheduleStep1Schema) ||
            (formStep === 2 && SMSBroadcastScheduleStep2Schema) ||
            (formStep === 3 && SMSBroadcastScheduleStep3Schema) ||
            (formStep === 4 && SMSBroadcastScheduleStep4Schema) ||
            null
          }
          initialTouched={userInput}
          validateOnChange={true}
          moduleId="sms_broadcast_crud"
          specificPermissionKey={formAccessType}
          divStyle={{
            gap: 0
          }}
        >

          <SMSBoxTopbar>
            <div className="left">
              <p className="heading">
                {" "}
                {!isEditingBroadcast && (
                  <IconButton onClick={() => handleBackClick()}>
                    <AppMaterialIcons
                      iconName={"ArrowBackIosRounded"}
                      style={{ fontSize: "0.8rem" }}
                    />
                  </IconButton>
                )}{" "}
                {isEditingBroadcast ? "Edit" : "Create"} Broadcast
              </p>
            </div>
            <div className="">
              <Box className="BtnsBox" display="flex" gap={"8px"} >
                {isView ? null : (
                  <AppButton
                    onClick={() =>
                      handleBackClick(formStep === 1 ? true : false)
                    }
                    variant="grey"
                    disabled={isEditingBroadcast}
                  >
                    {formStep === 1 ? "Reset" : "Back"}
                  </AppButton>
                )}
                {isView ? (
                  <AppButton
                    onClick={() => navigate("/broadcast/sms/scheduled")}
                    variant="grey"
                  >
                    Back
                  </AppButton>
                ) : null}
                {formStep === 4 ? (
                  <AppSubmitButton
                    title={
                      isEditingBroadcast
                        ? "Update"
                        : values.when_to_send === "broadcast_now"
                          ? "Broadcast Now"
                          : "Schedule Broadcast"
                    }
                  />
                ) : isView ? null : (
                  <AppSubmitButton
                    title="continue"
                    disabled={!smsTemplateListData?.length || ((formStep === 3 && (!broadcastCreditDetails?.valid)) || (broadcastCreditDetails?.credits_balance <= 0)) ? true : false}
                  />
                )}
              </Box>

            </div>
          </SMSBoxTopbar>


          <SMSBoxStepperbar>
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              {BreadCrumbsItems?.map((item: BreadCrumbItem) => {
                if (isView && item?.id === 1) {
                  return (
                    <BreadcrumbsItem iconColorCondition={item?.id === formStep} key={item?.id}>
                      <div className="icon">{item?.icon}</div>
                      <div className="text">
                        <p>{item?.name}</p>
                      </div>
                    </BreadcrumbsItem>
                  );
                } else if (!isView) {
                  return (
                    <BreadcrumbsItem iconColorCondition={item?.id === formStep} key={item?.id}>
                      <div className="icon">{item?.icon}</div>
                      <div className="text">
                        <p>{item?.name}</p>
                      </div>
                    </BreadcrumbsItem>
                  );
                }
              })}
            </Breadcrumbs>

            {formStep === 4 ? (
              <AppButton
                variant="outline"
                endIcon="ri-arrow-right-s-line"
                onClick={() => dispatch(testSMSData(true))}
              >
                Test SMS
              </AppButton>
            ) : null}
          </SMSBoxStepperbar>

          <StyledContentBox>
            <div className="formBox create-sms-broadcast">
              {formStep === 3 && broadcastCreditDetails != null ? (
                <StyledCreditBox>
                  <h3 className="titleText">Review Cost</h3>
                  <BroadcastCreditDetails creaditsData={broadcastCreditDetails} ></BroadcastCreditDetails>
                </StyledCreditBox>
              ) : null
              }

              {formStep === 1 && (
                <RenderFormStep1
                  setFormStep={setFormStep}
                  formStep={formStep}
                  isView={isView}
                />
              )}
              {formStep === 2 && <RenderFormStep2 />}
              {formStep === 3 && (
                <RenderFormStep3
                  setFormStep={setFormStep}
                  formStep={formStep}
                />
              )}
              {formStep === 4 && (
                <RenderFormStep4
                  setFormStep={setFormStep}
                  formStep={formStep}
                />
              )}
              <AppFormObserver setFormikProps={setFormikProps} />
            </div>

            {/* PREVIEW WIDGET */}
            <BroadcastPreview
              primaryColor={SMS_PRIMARY_COLOR}
              previewType="SMS"
              bodyValue={tempalteData}
            />
          </StyledContentBox>
          <AppModel
            isShowing={isShowing}
            onClose={() => toggle()}
            divStyle={{
              padding: 0,
            }}
            PaperProps={{
              style: {
                boxShadow: "none",
                width: "450px",
                height: "150px",
              },
            }}
          >
            <ModalContainer>
              <div className="header">
                <h4>Broadcast now!</h4>
                <div className="header-right">
                  <span onClick={() => toggle(false)}>
                    <AppMaterialIcons iconName="close" />
                  </span>
                </div>
              </div>
              <div className="body">
                <p>Are you sure, you want to broadcast this message now?</p>
              </div>
              <div className="footer">
                <AppButton
                  variant="grey"
                  onClick={() => {
                    toggle(false);
                  }}
                >
                  Cancel
                </AppButton>
                <AppButton
                  variant="default"
                  onClick={() => {
                    SMSNetworkService.updateSMSBroadcast(broadcastNowPayload?.payload, broadcastNowPayload?.id)
                      .then((res: any) => {
                        showAlert("Broadcast created", "success");
                        setLoading(false)
                        navigate("/broadcast/sms/history");
                      }).catch((err: any) => {
                        showAlert(
                          err?.response?.data?.error || "Unable to create broadcast. Please try again.",
                          "error"
                        );
                      }).finally(() => {
                        setLoading(false);
                      });
                  }}
                >
                  Broadcast Now
                </AppButton>
              </div>
            </ModalContainer>
          </AppModel>
        </ProtectedAppForm>
      ) : (
        <div style={{ height: "calc(100vh - 240px)" }}>
          <BroadcastNoData
            title="No Templates Found"
            subtitle="Please create at least one template to create the broadcast."
            onClick={handleButtonClick}
            buttonText="Create Template"
          />
        </div>
      )}
    </StyledCreateBroadcastWrap>
  );
};

export default HocBackdropLoader(BroadcastCreate);

const ModalContainer = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.875rem;
    height:40px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
      h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .body{
    p {
        padding: 0.875rem;
        color: ${theme.palette.default.darkGrey};
      }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
  }
`;